@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;1,400;1,500;1,600&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;1,100;1,300;1,400;1,500;1,700&display=swap");
@import "~antd/dist/antd.css";
iframe{
  align-content: baseline;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
}

.burger-bar {
  position: fixed;
    top: 0;
    bottom: 0;
	right: 0;
	height: 100vh;
    width: 100vw; /* Adjust this to change the width of the side bars */

  z-index: 10;
}
.hiddenx {
  display: flex;
	flex-direction: column;
	justify-content: center;
  transform:  translateX(100vw);
}
.visiblex {
  display: flex;
	flex-direction: column;
	justify-content: center;
  transform: translateX(0vw);
}
.visiblep {
  display: flex;
	flex-direction: column;
	justify-content: center;
  transform: translateX(-29.8em);
}
.scrollable-div {
  height: 17.875em;
  width: 90%;
  overflow-y: auto;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  font-family: "Poppins", sans-serif;
  font-size: 24;
  margin-left: 8.5%;
  margin-top: -0.675em;
}
.scrollable-divx {
  background-color: #ffffff67;
  height: 10em;
  width: 100%;
  display: flex;
  flex: 1;
  flex-wrap: nowrap;
  overflow-x: auto;   
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
    list-style-type: none;
    margin: 0;
    padding: 0;
  }/* width */
::-webkit-scrollbar {
  width:  0.625em;
  height:  0.625em;
  
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; border-radius:  0.625em;
  box-shadow: inset 0 0 0.3125em grey; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; border-radius:  0.625em;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
.menu {
  position: fixed;
    top: 0;
    bottom: 0;
	right: 0;
	height: 100vh;
    width: 28.5em; /* Adjust this to change the width of the side bars */
     background-color: #ffffff; /*Change this to the color you want */

    color: rgb(0, 0, 0);
  z-index: 10;
}
.hidden {
  display: flex;
	flex-direction: column;
	justify-content: center;
  transform:  translateX(29.8em);
  transition: all 0.5s ease-in-out;
}
.visible {
  display: flex;
	flex-direction: column;
	justify-content: center;
  transform: translateX(0em);
  transition: all 0.5s ease-in-out;
}

.displaytext {
  position: fixed;
    top: 0;
}
.hiddentext {
  display: flex;
	flex-direction: column;
	justify-content: center;
  transform:  translateX(0em);
  transition: all 0.5s ease-in-out;
}
.visibletext {
  display: flex;
	flex-direction: column;
	justify-content: center;
  transform: translateX(-12em);
  transition: all 0.5s ease-in-out;
}
.h1{
  font-family: "Montserrat", sans-serif;
}
.content{
  font-family: "Poppins", sans-serif;
  font-size: 18;
}
.hamMenuContent{
  font-family: "Poppins", sans-serif;
  background-color: #00000000;
  border: 0.21875em solid #00000000;
}
.hamMenuContent:hover{
  background-color: #d6d6d6;
  border: 0.21875em solid #d6d6d6;
  font-family: "Poppins", sans-serif;
}
.like{
height: 1.875em;
width: 1.875em;
}
.unlike{
height: 0em;
width:0em;
}